import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Heading,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  useToast,
} from "@chakra-ui/react";
import { RiDeleteBin6Line, RiEdit2Line } from "react-icons/ri";
import useProductStore from "../../zustand/useProductStore";
import { useNavigate } from "react-router-dom";
import useOrderStore from "../../zustand/useOrderStore";

const ProductDetails = ({ setActiveStep, setDiscountedTotal }) => {
  const navigate = useNavigate();
  const { orderDetails, setOrderDetails } = useOrderStore();
  const [discountCode, setDiscountCode] = useState(orderDetails.coupon || "");
  const [discountIsValid, setDiscountIsValid] = useState(
    orderDetails.coupon === "" ? null : !!orderDetails.coupon
  );
  const [loading, setIsLoading] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [total, setTotal] = useState(0);
  const toast = useToast();

  const {
    properties,
    removeProperty,
    setPropertyDetails,
    setEditPropertyDetails,
  } = useProductStore();

  const handleDelete = (id) => {
    if (properties.length >= 1) {
      var removedPropertyData = removeProperty(id);
      const lastProperty = properties[properties.length - 1];
      setPropertyDetails(lastProperty);
    }

    if (properties.length === 0) {
      navigate(0);
    }
  };

  const handleEdit = (id) => {
    const propertyToEdit = properties.find((property) => property.id === id);
    setEditPropertyDetails(propertyToEdit);
    setPropertyDetails(propertyToEdit);
    setActiveStep(0);
  };

  const handleDiscountCode = async () => {
    if (discountCode === "") {
      toast({
        title: "Please enter discount code",
        variant: "left-accent",
        status: "error",
        duration: 2000,
        position: "bottom-right",
      });
      return;
    }
    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/coupon/${discountCode}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    if (data.coupon == null) {
      toast({
        title: "Please enter a valid discount code",
        variant: "left-accent",
        status: "error",
        duration: 2000,
        position: "bottom-right",
      });
      setDiscountIsValid(false);
    } else {
      setDiscountPercentage(data.coupon.discount);
      setOrderDetails({ ...orderDetails, coupon: data.coupon.code });
      toast({
        title: "Discount code applied successfully!",
        variant: "left-accent",
        status: "success",
        duration: 2000,
        position: "bottom-right",
      });
      setDiscountIsValid(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const calculateTotal = async () => {
      let newTotal = 0;
      properties.forEach((property) => {
        newTotal += property.price;
      });

      if (orderDetails.coupon) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/coupon/${orderDetails.coupon}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();
          setDiscountPercentage(data.coupon.discount);
        } catch (error) {
          console.error("Error fetching discount percentage:", error);
        }
      }

      if (discountPercentage > 0) {
        newTotal -= newTotal * (discountPercentage / 100);
      }

      setTotal(newTotal.toFixed(2));
      setDiscountedTotal(newTotal.toFixed(2));
    };

    calculateTotal();
  }, [properties, discountPercentage, orderDetails.coupon, handleDelete]);
  return (
    <>
      {properties &&
        properties.map((property, index) => (
          <Box
            key={index}
            borderWidth={1}
            rounded={"8px"}
            borderColor={"gray.100"}
            mt={{ base: 2, md: 10 }}
          >
            <Table variant="simple">
              <Thead>
                <Tr borderWidth={0}>
                  <Th
                    borderWidth={0}
                    textTransform={"initial"}
                    color={"#000"}
                    fontWeight={"600"}
                  >
                    Property {properties.length > 1 ? index + 1 : ""}
                  </Th>
                  <Th borderWidth={0} w={"fit-content"}>
                    <Box display={"flex"} w={"100%"} justifyContent={"end"}>
                      <RiDeleteBin6Line
                        color="#929292"
                        cursor={"pointer"}
                        onClick={() => handleDelete(property.id)}
                      />
                      <RiEdit2Line
                        color="#929292"
                        cursor={"pointer"}
                        onClick={() => handleEdit(property.id)}
                      />
                    </Box>
                  </Th>
                </Tr>
                <Tr>
                  <Th
                    colSpan={2}
                    textTransform={"initial"}
                    color={"#000"}
                    fontWeight={"400"}
                  >
                    {" "}
                    <span style={{ fontWeight: 600 }}>Address: </span>
                    {property?.address}{" "}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {property?.selectedServicesObj &&
                  property?.selectedServicesObj.map((service, index) => (
                    <Tr fontSize={"13px"} color={"#000"} key={index}>
                      <Td fontWeight={600}>
                        {service.selectedButton
                          ? service.heading +
                          " + " +
                          service.selectedButton.heading
                          : service?.heading}
                      </Td>
                      <Td whiteSpace={{ base: "wrap", md: "nowrap" }}>
                        £
                        {service.selectedButton
                          ? service.selectedButton?.originalPrice
                          : service?.originalPrice}{" "}
                        (incl. VAT)
                      </Td>
                    </Tr>
                  ))}

                {property?.selectedBundleObj &&
                  property?.selectedBundleObj[0] !== undefined &&
                  property?.selectedBundleObj.map((bundle, index) => (
                    <Tr fontSize={"13px"} color={"#000"} key={index}>
                      <Td fontWeight={600}> {bundle?.heading} </Td>
                      <Td whiteSpace={{ base: "wrap", md: "nowrap" }}>
                        {" "}
                        £{bundle?.originalPrice} (incl. VAT){" "}
                      </Td>
                    </Tr>
                  ))}

                {property?.selectedAddOnObj &&
                  property?.selectedAddOnObj.map((addon, index) => (
                    <Tr fontSize={"13px"} color={"#000"} key={index}>
                      <Td fontWeight={600}> {addon?.heading} </Td>
                      <Td whiteSpace={{ base: "wrap", md: "nowrap" }}>
                        {" "}
                        £{addon?.originalPrice} (incl. VAT){" "}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
              <Tfoot>
                {discountPercentage > 0 && properties.length < 2 && (
                  <>
                    <Tr>
                      <Th color={"#000"} textTransform={"initial"} fontWeight={600}>
                        Discount
                      </Th>
                      <Th colSpan={2}>
                        <b style={{ color: "#000" }}>
                          {" "}
                          {discountPercentage}%{" "}
                        </b>{" "}
                      </Th>
                    </Tr>

                    <Tr>
                      <Th color={"#000"} textTransform={"initial"} fontWeight={600}>
                        Amount Saved
                      </Th>
                      <Th colSpan={2}>
                        <b style={{ color: "#000" }}>
                          £ {(properties.reduce((prev, curr) => prev + (curr.price ?? 0), 0) * (discountPercentage / 100)).toFixed(2)}
                        </b>
                      </Th>
                    </Tr>
                  </>
                )}

                <Tr>
                  <Th color={"#000"} textTransform={"initial"} fontWeight={600}>
                    Total
                  </Th>
                  <Th colSpan={2}>
                    <b style={{ color: "#000" }}>
                      {" "}
                      £ {properties.length > 1 ? property?.price : total}{" "}
                    </b>{" "}
                    (incl. VAT)
                  </Th>
                </Tr>
              </Tfoot>
            </Table>
          </Box>
        ))}

      {properties && properties.length > 1 && (
        <Box
          borderWidth={1}
          rounded={"8px"}
          borderColor={"gray.100"}
          mt={{ base: 2, md: 10 }}
        >
          <Table variant="simple">
            <Thead>
              {discountPercentage > 0 &&
                <>
                  <Tr borderBottomWidth={1}>
                    <Th
                      borderWidth={0}
                      color={"#000"}
                      textTransform={"initial"}
                      fontWeight={600}
                    >
                      Discount
                    </Th>
                    <Th borderWidth={0} colSpan={2}>
                      <b style={{ color: "#000" }}> {discountPercentage}% </b>
                    </Th>
                  </Tr>

                  <Tr>
                    <Th color={"#000"} textTransform={"initial"} fontWeight={600}>
                      Amount Saved
                    </Th>
                    <Th colSpan={2}>
                      <b style={{ color: "#000" }}>
                        £ {(properties.reduce((prev, curr) => prev + (curr.price ?? 0), 0) * (discountPercentage / 100)).toFixed(2)}
                      </b>
                    </Th>
                  </Tr>
                </>
              }
              <Tr>
                <Th
                  borderWidth={0}
                  color={"#000"}
                  textTransform={"initial"}
                  fontWeight={600}
                >
                  Total
                </Th>
                <Th borderWidth={0} colSpan={2}>
                  <b style={{ color: "#000" }}> £{total} </b> (incl. VAT)
                </Th>
              </Tr>
            </Thead>
          </Table>
        </Box>
      )}

      <Box mt={4}>
        <InputGroup size="md" mb={3}>
          <Input
            pr="4.5rem"
            type={"text"}
            placeholder={"Enter your discount code"}
            value={discountCode}
            onChange={(e) => setDiscountCode(e.target.value)}
          />
          <InputRightElement width="4.5rem">
            <Button
              h="1.75rem"
              size="sm"
              // colorScheme="teal"
              bgColor={"green.1000"}
              isLoading={loading}
              onClick={handleDiscountCode}
            >
              Apply
            </Button>
          </InputRightElement>
        </InputGroup>
      </Box>
    </>
  );
};

export default ProductDetails;
