import { extendTheme } from "@chakra-ui/react";
import { RxBorderWidth } from "react-icons/rx";
// import '@fontsource-variable/raleway'


const fonts = {
  body: `'Raleway', sans-serif`,
};

const colors = {
  white: {
    100: "#fff",
    200: "#FDFDFD",
    300: "#FBFBFB",
    400: "#EBF4FF",
  },
  black: {
    100: "#000",
    200: "#111",
    300: "#1111114d",
    400: "#404040",
    500:'#000000CC',
    600:'#00000066',
  },
  blue: {
    100: "#00274C",
    200: "#0e4476",
    300: "#C7E1FF66",
    400: "#E9F3FF",
    500: "#DAE7F63B",
    600: "#ACCCCF78",
    700: '#D3EAEC',
  },
  gray: {
    100: "#E3E3E3",
    200: "#2021241a",
    300: '#EFEFEF',
  },

  green: {
    100: "#ACCCCF",
    200: "#457377",
    300: "#DFECED",
    400: "#D1FEA4",
    500: '#9BFF37',
    600: '#31930F',
    700: '#B3F273',
    800: '#a8fd5266',
    900: '#EDFFDB',
    1000: '#7FFF00',
  },
  peach: {
    100: "#F9DFD2",
  },
};

const baseStyle = {
  // select the indicator part
  indicator: {
    bgColor: "white.100",
    borderColor: "green.700",

    "&[data-status=complete]": {
      bgColor: "green.700",
    },

    "&[data-status=active]": {
      bgColor: "green.700",
    },

    "&[data-status=incomplete]": {
      bgColor: "transparent",
    },
  },
  title: {
    color: 'black.100',
    fontSize: { md: "18px", base: "12px" },
  },
  separator: {
    background: 'green.700 !important'
  },
}

const stepperTheme = {
  baseStyle,
}

const breakpoints = {
  base: "0em", // 0px
  sm: "30em", // ~480px. em is a relative unit and is dependant on the font size.
  md: "49em", // ~768px
  lg: "62em", // ~992px
  xl: "80em", // ~1280px
  "2xl": "96em", // ~1536px
};

const components = {
  
  Checkbox: {
    baseStyle: {
      control: {
        bg: "#fff",
        fontSize: '12px',
        borderColor: "#000",
        borderWidth: 1,
        _checked: {
          bg: "#60BE03",
          borderColor: "blue.500",
          _hover: {
            bg: "#60BE03",
          }
        },
      },
    },
  },

  Radio: {
      baseStyle: {
        control: {
          bg: "transparent",
          borderColor: "#49454F",
          borderWidth: 2, 
          _checked: {
            bg: "white",
            borderColor: "#60BE03",
            borderWidth: 2,
            _before: {
              bg: "#60BE03",
            },
            _hover: {
              bg: '#fff',
              borderColor: "#60BE03",
              borderWidth: 2,
            },
          },
          
        },
      },
    },

  FormLabel: {
    variants: {
      label: {
        color: "#000 !important",
        fontSize: { md: "15px", base: "14px" },
        fontWeight: 400,
      },
    },
  },

  Link: {
    variants: {
      "black-link": {
        color: "black.100",
        fontWeight: 600,
        fontSize: "15px",
        _hover: {
          textDecoration: "none",
        },
      },

      footerLink: {
        fontWeight: 600,
        color: "gray.600 ",
        fontSize: ["11px", "13px", "14px"],
        lineHeight: { md: "15px", base: "14px" },
      },
    },
  },

  Text:{
    variants:{
      footerLink: {
        fontWeight: 600,
        color: "gray.600 ",
        fontSize: ["11px", "13px", "14px"],
        lineHeight: { md: "15px", base: "14px" },
      },
      "custom-para": {
          color: "black.100",
      },
    }
  },

  Input: {
    variants: {
      "bg-input": {
        field: {
          height: "43px",
          borderRadius: "8px",
          color: "gray.100",
          bg: "gray.200 !important",
          border: "none ",
          fontFamily: "var(--chakra-\fonts-openSans)",

          _placeholder: {
            color: "gray.100",
            fontFamily: "var(--chakra-\fonts-openSans)",
            fontWeight: 300,
            fontSize: "13px",
          },
        },
      },
      simple: {
        field: {
          borderRadius: "8px",
          bg: "D0D5DD !important",
          fontFamily: "var(--chakra-\fonts-openSans)",
          color: "black.100 !important",
          height: "39px",
          border: "1px solid",
          borderColor: "gray.900",
          borderRadius: "8px",
          pb: "5px",
          _placeholder: {
            color: "gray.100    ",
            fontFamily: "var(--chakra-\fonts-openSans)",
            lineHeight: "20px",
            fontWeight: 400,
            fontSize: "13px",
          },
        },
      },
    },
  },

  Textarea: {
    variants: {
      "bg-textarea": {
        minHeight: "140px",
        borderRadius: "8px",
        bg: "gray.200 !important",
        fontFamily: "var(--chakra-\fonts-openSans)",
        color: "black.100 !important",
        borderRadius: "8px",

        p: "20px 20px 20px 20px",
        _placeholder: {
          color: "black.100",
          fontFamily: "var(--chakra-\fonts-openSans)",
          lineHeight: "20px",
          fontWeight: 400,
          fontSize: "13px",
        },
      },
      simple: {
        minHeight: "100px",
        borderRadius: "8px",
        bg: "D0D5DD !important",
        fontFamily: "var(--chakra-\fonts-openSans)",
        color: "black.100 !important",
        border: "1px solid",
        borderColor: "gray.900",
        borderRadius: "8px",
        p: "9px 20px 20px 12px",
        _placeholder: {
          color: "gray.100    ",
          fontFamily: "var(--chakra-\fonts-openSans)",
          lineHeight: "20px",
          fontWeight: 400,
          fontSize: "13px",
          //   },
        },

        "::placeholder": {},
      },
      "bg-input": {
        field: {
          height: { sm: "55px", base: "53PX" },
          borderRadius: "8px",
          bg: "gray.200 !important",
          border: "none ",
          _placeholder: {
            color: "gray.100",
            fontWeight: 400,
            fontSize: "13px",
          },
        },

        color: "brand.100",
      },
    },
  },

  Select: {
    // parts: ['option', 'field', 'menu'],
    baseStyle: {
      field: {
        // color: 'purple',
        option: {
          borderRadius: '0',
          borderWidth: '0px',
          // color: 'purple',
        },
        menu: {
          bg: 'purple',
          borderRadius: '0',
        },
        control: {
          bg: 'red',
          borderRadius: '0',
        }
    },
    },
  },

  Button: {
    variants: {
      "solid-btn": {
        bg: "white",
        width: '100%',
        height: '26px',
        color: "black.100",
        fontWeight: 300,
        fontSize: {base: '10px', sm: '12px'},
        borderRadius: '0',
        transition: ".5s",
        padding: '0',
        _hover: {
          bg: { base: "none", md: "gray.100" },
        },
        _disabled: {
          _hover: {
            color: "white.100",
            bg: "blue.100 !important",
          },
        },
      },

      "solid-green-btn": {
        bg: "green.500",
        height: { sm: "40px", base: "36px" },
        width: {
          md: "139px",
          sm: "130px",
          base: "110px",
        },
        borderRadius:'7px',
        color: "black.100",
        fontWeight: 600,
        fontSize: { sm: "14px", base: "13px" },
        transition: ".5s",
        _hover: {
          bg: "green.500",
          color: "black.100",
        },
        _disabled: {
          _hover: {
            color: "white.100",
            bg: "green.200 !important",
          },
        },
      },

      "white-btn": {
        textAlign: "center",
        bg: "white.100",
        height: { sm: "38px", base: "36px" },

        width: {
          sm: "172px",
          base: "130px",
        },
        color: "blue.500",
        fontWeight: 600,
        fontSize: { sm: "13px", base: "12px" },
        transition: ".5s",
        _hover: {
          bg: "gray.500",
        },
      },
      
    },
  },

  Heading: {
    variants: {
      h1: {
        fontSize: ["20px", "20px", "20px", "26px", "26px", , "26px"],
        color: "black.100",
        fontWeight: 600,
      },
      
      h2: {
        fontSize: ["18px", "18px", "18px", "24px", "24px", , "24px"],
        color: "black.100",
        fontWeight: 600,
        fontFamily: 'inherit',
      },

      h3: {
        fontSize: ["16px", "16px", "16px", "18px", "18px", , "18px"],
        color: "black.100",
        fontWeight: 600,
      },

      p1: {
        fontWeight: 400,
        color: "black.100",
        fontSize: ["14px", "14px", "14px", "16px", "16px", "16px"],
      },

      p3: {
        fontWeight: 600,
        color: "black.600",
        fontSize: ["14px", "14px", "14px", "15px", "15px", "16px"],
      },

      p4: {
        fontWeight: 400,
        color: "gray.100",
        lineHeight: "15px",
        fontSize: "12px",
        margin: "0px",
      },

      p2: {
        fontWeight: 600,
        color: "gray.100",
        lineHeight: ["22px", "22px", "23px", "23px", "23px", "23px"],
        fontSize: ["14px", "15px", "15px", "18px", "18px", "18px"],
        margin: "0px",
      },
      
      h4: {
        fontSize: ["17px", "17px", "20px", "24px", "24px", , "24px"],
        color: "black.100",
        fontWeight: 700,
      },

      h5: {
        fontSize: ["16px", "16px", "18px", "18px", "18px"],
        color: "black.100",
        fontWeight: 500,
      },
    },
  },

  Stepper: stepperTheme,
};

export const theme = extendTheme({  colors, components, breakpoints });