import { create } from "zustand";

// Define the initial state of the property details
const initialState = {
  name: "",
  contact: "",
  email: "",
  companyName: "",
  acceptTC: false,
  coupon: ''
};

// Define the store
const useOrderStore = create((set) => ({
  orderDetails: initialState,
  setOrderDetails: (newDetails) =>
    set((state) => ({
      orderDetails: { ...state.orderDetails, ...newDetails },
    })),
  resetOrderDetails: () => set({ orderDetails: initialState }),
}));

export default useOrderStore;
