import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    VStack,
    HStack,
    Image,
    useToast
} from '@chakra-ui/react';
import useProductStore from '../../zustand/useProductStore';

const images = [
    { id: 'image1', src: 'images/Front View of the House.png', alt: 'Front View of House', label: 'Front View of House' },
    { id: 'image2', src: 'images/Rear View of the House - Copy.png', alt: 'Rear View of House', label: 'Rear View of House' },
    { id: 'image3', src: 'images/Garden - Copy.png', alt: 'Garden', label: 'Garden' }
];

function CustomModal({ isOpen, onClose, handleContinue }) {
    const { externalImages, setExternalImages, setPropertyDetails, propertyDetails } = useProductStore();
    const toast = useToast()

    const handleImageClick = (imageLabel) => {
        setExternalImages((prevExternalImages) => {
            const updated = prevExternalImages.includes(imageLabel)
                ? prevExternalImages.filter((label) => label !== imageLabel)
                : [...prevExternalImages, imageLabel];

            setPropertyDetails({
                ...propertyDetails,
                externalImages: updated
            });

            return updated;
        });
    };

    const handleContinueBtn = () => {
        if (externalImages.length === 0) {
            toast({
                title: "Please select atleast one option",
                variant: "left-accent",
                status: "error",
                duration: 2000,
                position: "bottom-right",
              });
        }
        else {
            handleContinue()
        }
    }
    

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent bg="white" color="black.100">
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={4} textAlign="center" mt={6}>
                        <Text fontSize="lg" fontWeight="bold">
                            Would you like to include external images?
                        </Text>
                        <Text fontSize="md">
                            This will count towards your allowance of 12 images. If you choose all three, you will have 9 remaining internal images.
                        </Text>
                        <VStack spacing={2} align="start">
                            {images.map(({ id, src, alt, label }) => (
                                <HStack key={id} onClick={() => handleImageClick(label)} cursor="pointer">
                                    <Image
                                        src={src}
                                        alt={alt}
                                        boxSize="30px"
                                        filter={externalImages.includes(label) ? 'invert(60%) sepia(40%) saturate(500%) hue-rotate(80deg)' : 'none'}
                                    />
                                    <Text>{label}</Text>
                                </HStack>
                            ))}
                        </VStack>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button 
                        colorScheme="blue"
                        mt={2} 
                        mx="auto" 
                        bgColor="green.1000"
                        onClick={() => handleContinueBtn()}
                        _hover={{ bg: 'green.1000', opacity: '0.7'}}
                    >
                        Continue
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default CustomModal;
