import React from 'react'
import { Box, Button, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import question from '../../assets/images/ep_question-filled.png'

const DescriptionModal = ({description}) => {
  
  const { isOpen, onOpen, onClose } = useDisclosure()
  

  return (
    <>
      <Button onClick={(e) => {e.stopPropagation(); onOpen();}} height={'auto'} width={'auto'} position='absolute' top={4} right={0} bg={'transparent'} _hover={{ bg: 'transparent' }} >
          <Image src={question} alt='question mark'  />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={{base: 'xs', sm: 'md', md:'3xl'}} isCentered >
        <ModalOverlay />
        <ModalContent bg={'white.100'} color={'black.100'} >
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody maxHeight={{base:'74vh', md: 'initial'}} overflow={"auto"}>
            <div dangerouslySetInnerHTML={{ __html: description}} /> 
          </ModalBody>

          <ModalFooter>  </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DescriptionModal