import React from 'react'
import { Image, Button, Container, Flex, Box } from '@chakra-ui/react'
import logo from '../../assets/images/logo.png'
import { Link } from '@chakra-ui/react'
import MenuBar from './MenuBar'

const Navbar = () => {
  return (
    <Container maxW='7xl' py={2}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Image src={logo} alt='logo' />
            <Flex gap={6} display={{ base: 'none', md: 'flex' }}>
                <Link variant={'black-link'}>About Us</Link>
                <Link variant={'black-link'}>Products And Services</Link>
                <Link variant={'black-link'} >Portfolio</Link>
                <Link variant={'black-link'}>Shop</Link>
                <Link variant={'black-link'}>Blog</Link>
                <Link variant={'black-link'}>Contact Us</Link>
            </Flex>
            <Button variant={"solid-green-btn"} display={{ base: 'none', md: 'block' }} fontWeight={600}>Book Now</Button>
            <Box display={{ base: 'block', md: 'none' }}>
              <MenuBar />
            </Box>
        </Flex>
    </Container>
  )
}

export default Navbar