import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button,
  } from '@chakra-ui/react'
  import React from 'react'
  import { RxHamburgerMenu } from "react-icons/rx";

  
  const MenuBar = () => {
    return (
        <Menu>
        <MenuButton as={Button} background={'transparent'}>
          <RxHamburgerMenu color='#000' />
        </MenuButton>
        <MenuList color={'black.100'} border={'none'}>
          <MenuItem >About Us</MenuItem>
          <MenuItem>Products And Services</MenuItem>
          <MenuItem>Portfolio</MenuItem>
          <MenuItem>Shop</MenuItem>
          <MenuItem>Blog</MenuItem>
          <MenuItem>Contact Us</MenuItem>
        </MenuList>
      </Menu>
    )
  }
  
  export default MenuBar