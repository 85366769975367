import React from 'react'
import { Image, Container, Flex, Box, Grid } from '@chakra-ui/react'
import logo from '../../assets/images/logo-black.png'
import fb from '../../assets/images/fb.png'
import insta from '../../assets/images/insta.png'
import { Link } from '@chakra-ui/react'

const Footer = () => {
  return (
    <Box bg={'gray.100'} py={6}>
        <Container maxW='7xl' py={2}>
            <Grid templateColumns={{ lg:'2fr 1fr 1fr', md:'1fr 1fr 1fr'}} gap={{ base: '4', md: '0' }}>
                <Box display={'flex'} alignItems={'center'} justifyContent={{base:'center', md:'start'}}>
                    <Image src={logo} alt='Dan Abramov' />
                </Box>
                
                <Flex gap={3} flexDirection={'column'} alignItems={{base:'center', md:'start'}}>
                    <Box display={'flex'} gap={3}>
                        <Link variant={'black-link'}><Image src={fb} alt='Dan Abramov' /></Link>
                        <Link variant={'black-link'}><Image src={insta} alt='Dan Abramov' /></Link>
                    </Box>
                    <Link variant={'black-link'} href='tel:02032876610' isExternal>020 3287 6610</Link>
                    <Link variant={'black-link'} href='mailto:general@spacephoto.co.uk' isExternal>general@spacephoto.co.uk</Link>
                    <Link variant={'black-link'} href="https://www.spacephoto.co.uk/terms-and-conditions/" isExternal>Terms and Conditions</Link>
                </Flex>

                <Flex gap={3} flexDirection={'column'} alignItems={{base:'center', md:'start'}}>
                    <Link variant={'black-link'}>Home</Link>
                    <Link variant={'black-link'}>About Us</Link>
                    <Link variant={'black-link'}>Products And Services</Link>
                    <Link variant={'black-link'} >Portfolio</Link>
                    <Link variant={'black-link'}>Shop</Link>
                </Flex>
            </Grid>
        </Container>
    </Box>
  )
}

export default Footer