// import React from "react";
// import { Button, Flex } from "@chakra-ui/react";
// import useProductStore from "../../zustand/useProductStore";

// const Time = ({ date }) => {
//   const { propertyDetails, setPropertyDetails } = useProductStore();
//   const times = [
//     { label: "9 AM", value: "9 AM" },
//     { label: "10 AM", value: "10 AM" },
//     { label: "11 AM", value: "11 AM" },
//     { label: "12 AM", value: "12 AM" },
//     { label: "1 PM", value: "1 PM" },
//     { label: "2 PM", value: "2 PM" },
//     { label: "3 PM", value: "3 PM" },
//     { label: "4 PM", value: "4 PM" },
//     { label: "5 PM", value: "5 PM" },
//     { label: "Decide Later", value: "Decide Later" },
//   ];

//   const handleTimeChange = (selectedOption) => {
//     const currentDateTime = propertyDetails?.dateTime?.find(
//       (option) => option.date === date
//     );
//     // If selected option is Decide Later then remove all the selected time
//     if (selectedOption === "Decide Later") {
//       const updatedDateTime = propertyDetails?.dateTime?.map((option) => {
//         if (option.date === date) {
//           option.time = [selectedOption];
//         }
//         return option;
//       });
//       setPropertyDetails({ dateTime: updatedDateTime });
//       return;
//     }
//     // If selected option is already selected then remove it
//     else if (currentDateTime?.time?.includes(selectedOption)) {
//       const updatedDateTime = propertyDetails?.dateTime?.map((option) => {
//         if (option.date === date) {
//           option.time = option.time.filter((time) => time !== selectedOption);
//         }
//         return option;
//       });
//       setPropertyDetails({ dateTime: updatedDateTime });
//     }
//     // If selected option is not selected then add it
//     else {
//       const updatedDateTime = propertyDetails?.dateTime?.map((option) => {
//         if (option.date === date) {
//           option.time = [
//             ...option.time.filter((e) => e !== "Decide Later"),
//             selectedOption,
//           ];
//         }
//         return option;
//       });
//       setPropertyDetails({ dateTime: updatedDateTime });
//     }
//   };

//   return (
//     <Flex
//       flexDirection={"column"}
//       gap={1}
//       width={{ base: "100%", sm: "max-content" }}
//     >
//       {times.map((data, index) => (
//         <Button
//           key={index}
//           _hover={{ bg: "green.900" }}
//           bg={
//             // propertyDetails?.time?.includes(data?.value)
//             //   ? "green.500"
//             //   : "transparent"
//             propertyDetails?.dateTime
//               ?.find((option) => option.date === date)
//               ?.time?.includes(data?.value)
//               ? "green.400"
//               : "transparent"
//           }
//           onClick={() => handleTimeChange(data?.value)}
//           borderWidth={1}
//           fontSize={"13px"}
//           fontWeight={"400"}
//           height={"32px"}
//           width={{ base: "100%", md: "170px" }}
//           borderColor={"gray.300"}
//           borderRadius={"4px"}
//         >
//           {" "}
//           {data?.label}{" "}
//         </Button>
//       ))}
//     </Flex>
//   );
// };

// export default Time;

import React from "react";
import { Button, Flex } from "@chakra-ui/react";
import useProductStore from "../../zustand/useProductStore";

const Time = () => {
  const { propertyDetails, setPropertyDetails } = useProductStore();

  const times = [
    { label: "9 AM", value: "9 AM" },
    { label: "10 AM", value: "10 AM" },
    { label: "11 AM", value: "11 AM" },
    { label: "12 AM", value: "12 AM" },
    { label: "1 PM", value: "1 PM" },
    { label: "2 PM", value: "2 PM" },
    { label: "3 PM", value: "3 PM" },
    { label: "4 PM", value: "4 PM" },
    { label: "5 PM", value: "5 PM" },
    { label: "Decide Later", value: "Decide Later" },
  ];

  const handleTimeChange = (selectedOption) => {
    // If selected option is Decide Later then remove all the selected time
    if (selectedOption === "Decide Later") {
      setPropertyDetails({ time: [selectedOption] });
      return;
    }
    // If selected option is already selected then remove it
    else if (propertyDetails?.time?.includes(selectedOption)) {
      const filteredOptions = propertyDetails?.time?.filter(
        (option) => option !== selectedOption
      );
      setPropertyDetails({ time: filteredOptions });
    }
    // If selected option is not selected then add it
    else {
      console.log(propertyDetails?.time, propertyDetails, "Property Details");
      const filteredOptions = propertyDetails?.time?.filter(
        (option) => option !== "Decide Later"
      );
      setPropertyDetails({ time: [...filteredOptions, selectedOption] });
    }
  };

  console.log("The selected Times are ", propertyDetails?.time);

  return (
    <Flex
      flexDirection={"column"}
      gap={1}
      width={{ base: "100%", sm: "max-content" }}
    >
      {times.map((data, index) => (
        <Button
          key={index}
          _hover={{ bg: "green.900" }}
          bg={
            propertyDetails?.time?.includes(data?.value)
              ? "#d1fea4"
              : "transparent"
          }
          onClick={() => handleTimeChange(data?.value)}
          borderWidth={1}
          fontSize={"13px"}
          fontWeight={"400"}
          height={"32px"}
          width={{ base: "100%", md: "170px" }}
          borderColor={"gray.300"}
          borderRadius={"4px"}
        >
          {" "}
          {data?.label}{" "}
        </Button>
      ))}
    </Flex>
  );
};

export default Time;
