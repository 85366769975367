
import {
    Alert, AlertIcon, AlertTitle, Box, Button, Collapse, Flex, Heading, Text, useToast, useDisclosure,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import ProductLayout from './ProductLayout'
import { getProductData, getBundleData, getAddOnData } from '../../data/products'
import useProductStore from '../../zustand/useProductStore'
import useDataStore from '../../zustand/useDataStore'
import CustomModal from '../Modal/CustomModal'



const Products = ({ handleNextStep }) => {

    const { products, bundles, addOns } = useDataStore();
    const [productData, setProductData] = useState([...products]);
    const [productBundleData, setProductBundleData] = useState([...bundles]);
    const [addOnData, setAddOnData] = useState([...addOns]);
    const [isModalOpen, setModalOpen] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        selectedServices,
        setSelectedServices,
        selectedBundle,
        selectedServicesTypes,
        setSelectedServicesTypes,
        setSelectedBundle,
        selectedAddOn,
        setSelectedAddOn,
        setServicePrice,
        discountedPrice,
        setDiscountedPrice,
        price,
        setPrice,
        setProductService,
        setProductBundle,
        setProductAddOn,
        setPropertyDetails,
        propertyDetails,
        properties
    } = useProductStore();

    useEffect(() => {
        async function fetchProductData() {
            const productData = await getProductData();
            console.log("productData -> useEffect", productData)
            setProductData(productData);
        }
        async function fetchBundleData() {
            const productBundleData = await getBundleData();
            console.log("productBundleData -> useEffect", productBundleData)
            setProductBundleData(productBundleData);
        }

        async function fetchAddOnData() {
            const addOnData = await getAddOnData();
            console.log("addOnData -> useEffect", addOnData)
            setAddOnData(addOnData);
        }

        fetchProductData();
        fetchBundleData();
        fetchAddOnData();
    }, []);

    const handlePrice = (serviceSelected, price) => {
        console.log(serviceSelected, price, "handlePrice")
        setServicePrice(prevState => {
            const clonedState = { ...prevState };
            console.log(clonedState[serviceSelected], "clonedState")
            if (clonedState[serviceSelected]?.every((val, idx) => val === price[idx])) {
                console.log("Deleting", clonedState[serviceSelected])
                delete clonedState[serviceSelected];
            } else {
                clonedState[serviceSelected] = price;
                console.log("Adding", clonedState[serviceSelected])
            }
            return clonedState;
        });
    }


    const formatter = Intl.NumberFormat('en-GB', {
        currency: 'GBP',
        style: 'currency',
        minimumFractionDigits: 2
    })

    const isProperty = properties.some(property => property.id === propertyDetails.id);
    const propertyIndex = properties.findIndex(property => property.id === propertyDetails.id);

    const productDataHeading = (properties.length > 1 && isProperty)
        ?
        `Please select services required (For Property ${propertyIndex + 1})`
        : ((properties.length > 1 && !isProperty)) ?
            `Please select services required (For Property ${properties.length + 1})`
            :
            'Please select services required *'


    const handleClick = (serviceIndex) => {

        // Check if the service is already selected
        const isSelected = selectedServices.includes(serviceIndex);
        // console.log("isSelected",isSelected)

        // If the service is selected, deselect it
        if (isSelected) {
            setSelectedServices(prevState => prevState.filter(index => index !== serviceIndex));
            // Deselect the button associated with the service
            setSelectedServicesTypes(prevState => {
                const clonedState = { ...prevState };
                delete clonedState[serviceIndex];
                return clonedState;
            });

            setSelectedAddOn(prevSelectedAddOn =>
                prevSelectedAddOn.filter(addOnId => {
                    const addOn = addOnData.find(addon => addon.id === addOnId);
                    return addOn?.productID !== serviceIndex;
                })
            );
        } else {
            setSelectedServices(prevState => [...prevState, serviceIndex]);

            setSelectedServicesTypes(prevState => {
                const defaultButtonData = productData.find(data => data.id === serviceIndex)?.button?.[0];
                const clonedState = { ...prevState };
                clonedState[serviceIndex] = defaultButtonData?.id ?? 0;
                return clonedState;
            });

            // Assuming default button index is 0
            const defaultButtonData = productData.find(data => data.id === serviceIndex)?.button?.[0];
            if (defaultButtonData) {
                handlePrice(defaultButtonData.heading, [defaultButtonData.discountedPrice ?? 0, defaultButtonData.originalPrice ?? 0]);
                handleImageChange(defaultButtonData.image);
                handleDescriptionChange(defaultButtonData.description);
                setSelectedButtonServiceID(serviceIndex);
            }
        }

        // Select the best bundle based on selected services
        let selectedServiceIDs = isSelected ?
            selectedServices.filter(index => index !== serviceIndex) :
            [...selectedServices, serviceIndex];

        let bestBundleId = null;
        let maxServiceCount = 0;
        let minPrice = Number.MAX_SAFE_INTEGER;

        // Find a bundle that has all selected services

        const NoBundleServices = {};
        selectedServiceIDs.forEach(serviceId => {
            const selectedServiceObj = productData.find(product => product.id === serviceId);
            if (selectedServiceObj) {
                const buttonIndex = selectedServiceObj?.button?.findIndex(button => button.id === selectedServicesTypes[serviceId]);
                if (buttonIndex !== -1 && selectedServiceObj.button?.[buttonIndex]) {
                    NoBundleServices[serviceId] = selectedServiceObj.button[buttonIndex].isBundleAllowed;
                } else {
                    NoBundleServices[serviceId] = true; // Default to true if no button or bundle restriction
                }
            } else {
                NoBundleServices[serviceId] = true; // Default to true if service is not found
            }
        });

        if (Object.values(NoBundleServices).filter(e => !!e).length < 1) {
            setSelectedBundle([]);
            return;
        }

        selectedServiceIDs = selectedServiceIDs.filter(e => NoBundleServices[e]);

        const exactMatchBundle = productBundleData.find(bundle => {
            return bundle.productID.length === selectedServiceIDs.length &&
                bundle.productID.every(id => selectedServiceIDs.includes(id))
        });

        if (exactMatchBundle) {
            bestBundleId = exactMatchBundle.id;
        } else if (!exactMatchBundle && selectedServiceIDs.length > 1) {
            // Find a bundle with the maximum number of services
            const selectedServiceIDsSet = new Set(selectedServiceIDs);
            productBundleData
                .filter(data => selectedServiceIDs.length >= data.productID.length)
                .filter(bundle => bundle.productID.every(id => selectedServiceIDsSet.has(id)))
                .forEach(bundle => {
                    const bundleServiceCount = bundle.productID.filter(id => selectedServiceIDs.includes(id)).length;
                    if (bundleServiceCount > maxServiceCount) {
                        bestBundleId = bundle.id;
                        maxServiceCount = bundleServiceCount;
                        minPrice = bundle.discountedPrice;
                    } else if (bundleServiceCount === maxServiceCount && bundle.discountedPrice < minPrice) {
                        bestBundleId = bundle.id;
                        minPrice = bundle.discountedPrice;
                    }
                });
        }

        setSelectedBundle(bestBundleId ? [bestBundleId] : []);
    };

    const handleButtonClick = (e, serviceIndex, buttonIndex, data) => {
        e.stopPropagation();

        const isSelected = selectedServices.includes(serviceIndex);
        const currentButtonIndex = selectedServicesTypes[serviceIndex];

        console.log("TESTTEST")

        if (isSelected && currentButtonIndex === data?.id) {
            // Deselect the service and button
            setSelectedServices(prevState => prevState.filter(index => index !== serviceIndex));
            setSelectedServicesTypes(prevState => {
                const clonedState = { ...prevState };
                delete clonedState[serviceIndex];
                return clonedState;
            });

            // Deselect the add-ons related to this service
            setSelectedAddOn(prevSelectedAddOn =>
                prevSelectedAddOn.filter(addOnId => {
                    const addOn = addOnData.find(addon => addon.id === addOnId);
                    return addOn.productID !== serviceIndex;
                })
            );
        } else {
            // Select the service and the clicked button
            if (!isSelected) {
                setSelectedServices(prevState => [...prevState, serviceIndex]);
            }
            setSelectedServicesTypes(prevState => {
                const clonedState = { ...prevState };
                clonedState[serviceIndex] = data?.id ?? buttonIndex;
                return clonedState;
            });

            const defaultButtonData = productData.find(data => data.id === serviceIndex)?.button?.[buttonIndex];
            if (defaultButtonData) {
                handlePrice(defaultButtonData.heading, [defaultButtonData.discountedPrice ?? 0, defaultButtonData.originalPrice ?? 0]);
                setSelectedButtonServiceID(serviceIndex);
                handleImageChange(defaultButtonData.image);
                handleDescriptionChange(defaultButtonData.description);
            }
        }

        console.log(currentButtonIndex, "Testing")

        // Bundle selection logic
        const selectedServiceObj = productData.find(product => product.id === serviceIndex);
        const NoBundleServices = {}
        Array.from([...selectedServices, serviceIndex]).forEach(e => {
            const temp = { ...selectedServicesTypes };
            temp[serviceIndex] = data?.id ?? buttonIndex
            const buttonId = temp[e];
            const buttonData = productData.find(data => data.id === e)?.button;
            const selectedButton = buttonData?.find(b => b.id === buttonId)
            NoBundleServices[e] = selectedButton?.isBundleAllowed != null ? !!selectedButton?.isBundleAllowed : true;
        });

        if (selectedServiceObj) {
            // Select the best bundle based on selected services
            let updatedSelectedServices = isSelected && currentButtonIndex === data?.id
                ? selectedServices.filter(index => index !== serviceIndex)
                : [...selectedServices.filter(index => index !== serviceIndex), serviceIndex];

            updatedSelectedServices = updatedSelectedServices.filter(e => NoBundleServices[e])

            let bestBundleId = null;
            let maxServiceCount = 0;
            let minPrice = Number.MAX_SAFE_INTEGER;

            const exactMatchBundle = productBundleData.find(bundle =>
                bundle.productID.length === updatedSelectedServices.length &&
                bundle.productID.every(id => updatedSelectedServices.includes(id))
            );

            if (exactMatchBundle) {
                bestBundleId = exactMatchBundle.id;
            } else if (updatedSelectedServices.length > 1) {
                const updatedSelectedServiceSet = new Set(updatedSelectedServices);
                productBundleData
                    .filter(bundle => updatedSelectedServices.length >= bundle.productID.length)
                    .filter(bundle => bundle.productID.every(id => updatedSelectedServiceSet.has(id)))
                    .forEach(bundle => {
                        const bundleServiceCount = bundle.productID.filter(id => updatedSelectedServices.includes(id)).length;
                        if (bundleServiceCount > maxServiceCount) {
                            bestBundleId = bundle.id;
                            maxServiceCount = bundleServiceCount;
                            minPrice = bundle.discountedPrice;
                        } else if (bundleServiceCount === maxServiceCount && bundle.discountedPrice < minPrice) {
                            bestBundleId = bundle.id;
                            minPrice = bundle.discountedPrice;
                        }
                    });
            }

            setSelectedBundle(bestBundleId ? [bestBundleId] : []);
        } else {
            setSelectedBundle([]);
        }
    };

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedDescription, setSelectedDescription] = useState(null);
    const [selectedButtonServiceID, setSelectedButtonServiceID] = useState(null);
    console.log("selectedImage", selectedImage);
    // To handle image chnage on product having options or button
    const handleImageChange = (image) => {
        setSelectedImage(image);
    };

    const handleDescriptionChange = (description) => {
        setSelectedDescription(description);
    };

    const handleBundleClick = (bundleId) => {
        const bundle = productBundleData.find(b => b.id === bundleId);

        if (!bundle) return;

        const isSelected = selectedBundle.includes(bundleId);
        const hasDuplicate = bundle.productID.some(serviceId => selectedServices.includes(serviceId));

        if (isSelected) {
            // Deselect the bundle
            setSelectedBundle(prevSelectedBundle => prevSelectedBundle.filter(id => id !== bundleId));

            // Deselect the services associated with this bundle
            setSelectedServices(prevSelectedServices =>
                prevSelectedServices.filter(serviceId => !bundle.productID.includes(serviceId))
            );

            setSelectedAddOn(prevSelectedAddOn =>
                prevSelectedAddOn.filter(addOnId => {
                    const addOn = addOnData.find(addon => addon.id === addOnId);
                    return !bundle.productID.includes(addOn.productID);
                }))

            // Clear button selections for deselected services
            setSelectedServicesTypes(prevSelectedTypes => {
                const clonedState = { ...prevSelectedTypes };
                bundle.productID.forEach(serviceIndex => {
                    delete clonedState[serviceIndex];
                });
                return clonedState;
            });

            // Clear the price and image for deselected services
            bundle.productID.forEach(serviceIndex => {
                const prod = productData.find(data => data.id === serviceIndex);
                const defaultButtonData = prod?.button?.find(button => button.isBundleAllowed) ?? prod?.button?.[0];
                if (defaultButtonData) {
                    handlePrice(defaultButtonData.heading, [0, 0]);
                }
            });

            return;
        }

        if (hasDuplicate) {
            // If there are duplicate services, deselect all bundles and select only the clicked bundle
            setSelectedBundle([bundleId]);
            setSelectedServices(bundle.productID);
            setSelectedServicesTypes({});

            bundle.productID.forEach(serviceIndex => {
                const prod = productData.find(data => data.id === serviceIndex);
                const defaultButtonData = prod?.button?.find(button => button.isBundleAllowed);
                setSelectedServicesTypes(prevSelectedTypes => {
                    const clonedState = { ...prevSelectedTypes };
                    clonedState[serviceIndex] = defaultButtonData?.id ?? prod?.button?.[0]?.id ?? 0;
                    return clonedState;
                });

                // Assuming default button index is 0
                if (defaultButtonData) {
                    handlePrice(defaultButtonData.heading, [defaultButtonData.discountedPrice ?? 0, defaultButtonData.originalPrice ?? 0]);
                    handleImageChange(defaultButtonData.image);
                    handleDescriptionChange(defaultButtonData.description);
                    setSelectedButtonServiceID(serviceIndex);
                }
            });
            return;
        }

        // Select the new bundle
        setSelectedBundle(prevSelectedBundle => [...prevSelectedBundle, bundleId]);

        // Select the services associated with this bundle
        setSelectedServices(prevSelectedServices => [...prevSelectedServices, ...bundle.productID.filter(serviceId => !prevSelectedServices.includes(serviceId))]);

        // Set button selections for the services
        bundle.productID.forEach(serviceIndex => {
            const prod = productData.find(data => data.id === serviceIndex);
            const defaultButtonData = prod?.button?.find(button => button.isBundleAllowed);
            setSelectedServicesTypes(prevSelectedTypes => {
                const clonedState = { ...prevSelectedTypes };
                clonedState[serviceIndex] = defaultButtonData?.id ?? prod?.button?.[0]?.id ?? 0;
                return clonedState;
            });

            // Assuming default button index is 0
            if (defaultButtonData) {
                handlePrice(defaultButtonData.heading, [defaultButtonData.discountedPrice ?? 0, defaultButtonData.originalPrice ?? 0]);
                handleImageChange(defaultButtonData.image);
                handleDescriptionChange(defaultButtonData.description);
                setSelectedButtonServiceID(serviceIndex);
            }
        });
    };

    const productBundleDataHeading = 'Or select services in a package'
    const handleAddOnClick = (serviceIndex) => {
        setSelectedAddOn((prevState) => {
            if (prevState.includes(serviceIndex)) {
                return prevState.filter((index) => index !== serviceIndex);
            } else {
                return [...prevState, serviceIndex];
            }
        });
    };

    const addOnDataHeading = 'Additional services'
    // Addons Logic Ends Here

    const calculateTotalPrice = () => {
        let totalPrice = 0;
        let savedPrice = 0;

        // Filter out services that are part of any selected bundle and collect full service data
        const servicesNotInBundles = selectedServices.reduce((acc, serviceIndex) => {
            const service = productData.find(item => item.id === serviceIndex);
            const selectedButtonIndex = selectedServicesTypes?.[serviceIndex];
            const selectedButton = selectedButtonIndex !== undefined && service?.button?.find(e => e.id === selectedButtonIndex) ? service?.button?.find(e => e.id === selectedButtonIndex) : null;

            if (!selectedBundle.some(bundleId => {
                const bundle = productBundleData.find(b => b.id === bundleId);
                return bundle && bundle.productID.includes(serviceIndex);
            })) {
                acc.push({ ...service, selectedButton });
            }
            return acc;
        }, []);

        // Calculate total price of selected services excluding those in selected bundles
        servicesNotInBundles.forEach(service => {
            console.log(service, "service")
            if (service.selectedButton) {
                totalPrice += service.selectedButton.originalPrice;
            } else {
                totalPrice += service.originalPrice;
            }
        });

        // Calculate total saved price of all selected services
        selectedServices.forEach(serviceIndex => {
            const service = productData.find(item => item.id === (serviceIndex?.id ?? serviceIndex));
            if (!service) return;
            const selectedButtonIndex = selectedServicesTypes?.[serviceIndex?.id ?? serviceIndex];
            if (selectedButtonIndex !== undefined && service?.button?.find(e => e.id === selectedButtonIndex)) {
                const selectedButton = service.button.find(e => e.id === selectedButtonIndex);
                savedPrice += selectedButton.originalPrice;
            } else {
                savedPrice += service.originalPrice;
            }
        });

        // Calculate total price of selected bundles
        const selectedBundles = selectedBundle.map(bundleId => {
            return productBundleData.find(b => b.id === bundleId);
        });

        selectedBundles.forEach(bundle => {
            if (bundle) {
                totalPrice += bundle.discountedPrice;
            }
        });

        // Calculate total price of selected addons
        const selectedAddOns = selectedAddOn.map(addOnId => {
            return addOnData.find(item => item.id === addOnId);
        });

        selectedAddOns.forEach(addOn => {
            if (addOn) {
                totalPrice += addOn.originalPrice;
                savedPrice += addOn.originalPrice;
            }
        });

        setDiscountedPrice(totalPrice);
        setPrice(savedPrice);
        setProductService(servicesNotInBundles);
        setProductBundle(selectedBundle);
        setProductAddOn(selectedAddOn);
        setPropertyDetails({
            ...propertyDetails,
            selectedServices,
            selectedAddOn,
            selectedBundle,
            selectedServicesTypes,
            selectedServicesObj: servicesNotInBundles,
            selectedBundleObj: selectedBundles,
            selectedAddOnObj: selectedAddOns,
            price: totalPrice,
        });

        return {
            totalPrice,
            // services: servicesNotInBundles,
            // bundles: selectedBundles,
            // addOns: selectedAddOns
        };
    };

    useEffect(() => {
        calculateTotalPrice();
    }, [selectedAddOn, selectedBundle, selectedServices]);


    const filteredAddOnData = addOnData.filter(addon => {
        console.log(selectedServicesTypes[addon.productID], addon.buttonId, "filtering AddOn")
        return selectedServices.includes(addon.productID) && (addon.buttonId?.length > 0 ? addon.buttonId.includes(selectedServicesTypes[addon.productID]) : true)
    });

    const toast = useToast()
    const handleContinue = () => {
        if (selectedServices.length === 0) {
            toast({
                title: 'Please select at least 1 service',
                variant: 'left-accent',
                status: 'error',
                duration: 2000,
                position: 'bottom-right'
                // isClosable: true,
            });
        } else {
            if(selectedServices.includes("667ae37c5f6f8ca125e62e95") && selectedServices.length < 2 && selectedBundle.length === 0 && selectedAddOn.length === 0 ){
                setModalOpen(true);
                onOpen();
            } else {
                handleNextStep();
            }
        }
    };
    
    const handleModalContinue = () => {
        handleNextStep();
    }

    return (
        <Box position={'relative'}>

            {/* <Flex flexDirection='column' gap={2} alignItems={'center'} mb={8} display={{ base: 'flex', md: 'none' }}>
                <Heading textAlign='center' variant="p3">Working hours are Mon. - Fri. 9 AM to 5 PM</Heading>
                <Heading textAlign='center' variant="p3">The displayed prices are applicable for London within M25 area</Heading>
                <Heading textAlign='center' variant="p3">For services required outside of this region or during non-standard working hours, please feel free to contact us for further assistance</Heading>
            </Flex> */}

            <ProductLayout productData={productData} heading={productDataHeading} paddingTop={3} imageWidth={"38px"} selectedServices={selectedServices} handleClick={handleClick} handleButtonClick={handleButtonClick} selectedServicesTypes={selectedServicesTypes} selectedDescription={selectedDescription} selectedImage={selectedImage} selectedButtonServiceID={selectedButtonServiceID} minH="110px" />
            <ProductLayout productData={productBundleData} heading={productBundleDataHeading} paddingTop={8} paddingBottom={8} imageWidth={"auto"} selectedServices={selectedBundle} handleClick={handleBundleClick} minTextHeight={'30px'} />
            {
                <Collapse in={filteredAddOnData.length > 0} startingHeight={10}>
                    <ProductLayout productData={filteredAddOnData} heading={addOnDataHeading} paddingTop={3} paddingBottom={2} imageWidth={"auto"} selectedServices={selectedAddOn} handleClick={handleAddOnClick} />
                </Collapse>
            }
            {isModalOpen &&
                <CustomModal isOpen={isOpen} onClose={onClose} handleContinue={handleModalContinue} />
            }
            <Box position={{ base: 'sticky', md: 'initial' }} bottom={0} bg={'white'} py={{ base: 2, md: 0 }} zIndex={'50'}>
                <Flex flexDirection='column' gap={2} alignItems={'center'} my={4}>
                    <Heading textAlign='center' variant="h2">Your total cost</Heading>
                    <Heading textAlign='center' variant="p1" display={'flex'} gap={2} alignItems={'center'}>
                        <Text color={'green.600'} fontWeight={600} fontSize={'24px'}>{formatter.format(discountedPrice || 0)}</Text>
                        {(selectedBundle.length > 0) && <> instead of {formatter.format(price || 0)}</>}
                        <Text color={'black.600'} fontWeight={'600'}>* excluding VAT</Text>
                    </Heading>
                    <Button variant={"solid-green-btn"} fontWeight={400} onClick={handleContinue}>Continue</Button>
                </Flex>
            </Box>

            <Flex flexDirection='column' gap={2} alignItems={'center'} mb={8}>
                <Heading textAlign='center' variant="p3">Working hours are Mon. - Fri. 9 AM to 5 PM</Heading>
                <Heading textAlign='center' variant="p3">The displayed prices are applicable for London within M25 area</Heading>
                <Heading textAlign='center' variant="p3">For services required outside of this region or during non-standard working hours, please feel free to contact us for further assistance</Heading>
            </Flex>


        </Box>
    )
}

export default Products 