import {
  Box,
  Button,
  Container,
  Grid,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import OrderDetails from "./OrderDetails";
import BillingDetails from "./BillingDetails";
import CustomButton from "../Button/CustomButton";
import ProductDetails from "./ProductDetails";
import Thankyou from "../Thankyou/Thankyou";
import useProductStore from "../../zustand/useProductStore";
import useOrderStore from "../../zustand/useOrderStore";
import PayPalComponent from "../PaPalComponent/PayPalComponent";

const Checkout = ({ setActiveStep, handleReset, handlePreviousStep }) => {
  const [showThankyou, setShowThankyou] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { properties } = useProductStore();
  const { orderDetails } = useOrderStore();
  const [paymentOption, setPaymentOption] = useState("Apple Pay");
  const [orderId, setOrderId] = useState(null);
  const [total, setTotal] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handlePaymentOptionChange = (newPaymentOption) => {
    setPaymentOption(newPaymentOption);
  };

  const toast = useToast();

  // const total = properties.reduce(
  //   (acc, property) => acc + (property.price || 0),
  //   0
  // );

  const validateFields = () => {
    const errors = {};
    const { name, contact, email, acceptTC } = orderDetails;

    if (!name) {
      errors.name = "Name is required.";
    }

    if (!contact) {
      errors.contact = "Contact Number is required.";
    }

    if (!email) {
      errors.email = "Email is required.";
    }

    if (!acceptTC) {
      errors.acceptTC = "Please accept Terms and Conditions.";
    }

    return errors;
  };

  const handlePlaceOrder = async () => {
    const errors = validateFields();
    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) {
      Object.values(errors).forEach((error) => {
        toast({
          title: error,
          variant: "left-accent",
          status: "error",
          duration: 2000,
          position: "bottom-right",
        });
      });
    } else {
      setIsLoading(true);
      const orderData = {
        ...orderDetails,
        orderData: properties,
        paymentMethod: paymentOption,
      };
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/orders`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(orderData),
        }
      );
      const data = await response.json();
      if (data.error) {
        toast({
          title: data.error,
          variant: "left-accent",
          status: "error",
          duration: 2000,
          position: "bottom-right",
        });
        setIsLoading(false);
      } else {
        if (
          paymentOption === "PayPal" ||
          paymentOption === "Debit & Credit Cards"
        ) {
          setOrderId(data.order_id);
          onOpen();
        } else {
          handlePayment(data);
        }
      }
    }
  };

  const handlePayment = async (data) => {
    setIsLoading(false);
    if (data?.checkout_url) {
      window.location.href = data.checkout_url;
    }
  };

  const handlePaymentSuccess = (details) => {
    toast({
      title: "Payment Successful",
      description: `Transaction completed by ${details.payer.name.given_name}`,
      status: "success",
      duration: 2000,
      position: "bottom-right",
      containerStyle: { color: "white" },
    });
    setShowThankyou(true);
    setTimeout(() => {
      setShowThankyou(false);
      handleReset();
    }, 1500);
  };

  const handlePaymentError = (error) => {
    console.error("PayPal Payment Error:", error);
    toast({
      title: "Payment Failed",
      description: "An error occurred during the payment process.",
      status: "error",
      duration: 2000,
      position: "bottom-right",
      containerStyle: { color: "white" },
    });
  };

  return (
    <Container maxW={"7xl"}>
      <Grid
        templateColumns={{ base: "1fr", sm: "1fr 1fr", md: "1fr 1fr 1fr" }}
        gap={10}
        my={6}
      >
        <Box>
          <OrderDetails />
        </Box>

        <Box>
          <BillingDetails onPaymentOptionChange={handlePaymentOptionChange} />
        </Box>

        <Box
          gridColumn={{ base: "1", sm: "span 2", md: "auto" }}
          maxW={"md"}
          mx={"auto"}
        >
          <ProductDetails
            setActiveStep={setActiveStep}
            setDiscountedTotal={setTotal}
          />
        </Box>
      </Grid>

      <Box
        display={"flex"}
        alignItems={"center"}
        w={"100%"}
        mb={20}
        flexDirection={"column"}
        gap={4}
      >
        <CustomButton
          text={"Place Order"}
          bgColor={"green.1000"}
          width={"180px"}
          onClick={handlePlaceOrder}
          loading={isLoading}
        />

        <CustomButton
          text={"Back"}
          onClick={handlePreviousStep}
          width={"180px"}
        />
      </Box>

      <Box display={"none"}>
        <PayPalComponent
          orderId={orderId}
          amount={total}
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentError={handlePaymentError}
          isOpen={isOpen}
          onClose={onClose}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      </Box>
      {showThankyou && <Thankyou />}
    </Container>
  );
};

export default Checkout;
