import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { theme } from "../src/theme/theme";
import './App.css';
import { Box, ChakraProvider } from '@chakra-ui/react';
import { Home } from './pages/Home/Home';

function App() {
  return (
      <ChakraProvider theme={theme}>
          <Box>
            <Router>
              <Routes>
                <Route path="/" element={<Home />} />
              </Routes>
            </Router>
          </Box>
      </ChakraProvider>
  );
}

export default App;
